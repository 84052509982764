<template>
  <v-autocomplete
      v-model="model"
      color="grey lighten-1"
      :items="entries"
      :loading="isLoading"
      :search-input.sync="search"
      dense
      filled
      :chips="chips"
      :small-chips="chips"
      :multiple="multiple"
      no-data-text="Не знайдено жодного бух. рахунку"
      hide-details
      :label="label"
      :disabled="disabled"
      placeholder="почніть вводити текст для пошуку"
      @change="$emit('autocompleteChange', model)"
      return-object
  >
    <template v-slot:selection="data" v-if="chips">
      <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
          class="my-1"
          color="white"
      >
          <v-icon :color="getBuildingIcon(data.item.type, 'color')" class="mr-3">
            {{ getBuildingIcon(data.item.type, 'icon') }}
          </v-icon>
        <span class="grey--text text--darken-3">{{ data.item.text }}</span>
      </v-chip>
    </template>
    <template v-slot:item="{ active, item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }" style="max-height: 20px !important;">
        <v-list-item-action class="my-2" v-if="multiple">
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-icon>
          <v-icon :color="getBuildingIcon(item.type, 'color')" size="20" :class="multiple ? 'mr-5' : ''">
            {{ getBuildingIcon(item.type, 'icon') }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-html="item.text" style="font-size: .88rem !important;"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import SelectAPI from '@/utils/axios/autocomplite'
import {ALERT_SHOW} from "@/store/actions/alert";
import {getBuildingIcon} from "@/utils/icons";
import {mapGetters} from "vuex";

export default {
  name: "ChartOfAccount",
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, Array],
      default: null
    },
    showName: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Рахунок'
    }
  },
  computed: {
    ...mapGetters({
      select_items: 'getChartOfAccountSelect'
    })
  },
  data() {
    return {
      entries: [],
      isLoading: false,
      model: this.multiple ? [] : null,
      search: null,
    }
  },
  methods: {
    setModelFromFetch() {
      const value_is_array = Array.isArray(this.value)

      if (this.multiple) {
        if (value_is_array) {
          this.value.forEach(building_id => {
            if (building_id) {
              if (this.model === null || this.model === undefined) {
                this.model = this.entries.map(e => e.value === building_id)
              } else {
                this.model = []
                this.model = this.entries.map(e => e.value === building_id)
              }
            }
          })
        } else {
          this.model = this.entries.find(e => e.value === this.value)
        }
      } else {
        this.model = this.entries.find(e => e.value === this.value) || {}
      }
    },
    fetch() {
      let payload = {}
      if (this.select_items.length) {
        this.entries = this.select_items
        this.entries.forEach(item => {
          item.text = this.showName ? `${item.type} (${item.text})` : item.type
        })
        this.setModelFromFetch()
        this.$emit('autocompleteChange', this.model)
        this.isLoading = false
      } else {
        SelectAPI.chart_of_account_select(payload)
            .then(res => {
              const { data } = res
              this.entries = data
              this.entries.forEach(item => {
                item.text = this.showName ? `${item.type} (${item.text})` : item.type
              })
              this.setModelFromFetch()
              this.$emit('autocompleteChange', this.model)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            })
            .finally(() => (this.isLoading = false))
      }
    },
    remove (item) {
      const fElement = this.model.find(i => i.value === item.value)
      const index = this.model.indexOf(fElement)
      if (index >= 0) this.model.splice(index, 1)
    },
    getBuildingIcon
  },
  watch: {
    search () {
      if (this.entries.length > 0) return

      if (this.isLoading) return

      this.isLoading = true

      this.fetch()
    },
    value: {
      handler(payload) {
        if (payload) {
          if (this.multiple) {
            let models_items = []
            payload.forEach(building => {
              models_items.push(this.entries.find(item => item.value === building))
            })
            this.model = models_items
          } else {
            this.model = this.entries.find(item => item.value === payload)
          }
        } else {
          this.model = this.multiple ? [] : null
        }
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped>

</style>